<template>
  <div class="modal-items" ref="modalBg">
    <input
      @input="checkSearchResults"
      v-model.trim="inputSearchValue"
      @keyup.enter="createNewItem"
      ref="inputModal"
      type="text"
      placeholder="Найти..."
    />
    <div class="chosen-options-bg" v-if="multiple && value.length">
      <div class="chosen-options" v-for="val in value" :key="val.id">
        <div>{{ val[itemName] }}</div>
        <i class="icon-cross" @click.stop="deleteOption(val)"></i>
      </div>
    </div>
    <div
      class="button-chose-all"
      v-if="multiple && !maxCount && value.length !== options.length && !inputSearchValue.length"
      @click="selectAllOption"
    >
      {{ channelUsers ? "Выбрать все" : "Выбрать всех" }}
    </div>
    <OptionSelect
      v-for="(option, index) in options"
      :option="option"
      :index="index"
      :hoverIndex="hoverIndex"
      :value="value"
      :itemName="itemName"
      :hiddenItems="hiddenItems"
      :key="option.id"
      v-if="!loader"
      @choseOption="pushOption"
      @deleteChoseOption="deleteOption"
    />
    <div class="empty-bg" v-if="!loader && !options.length">Ничего не найдено :(</div>
    <div class="loader-bg" v-if="loader">
      <div class="loader-content">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import httpClient from "@/api/client/httpClient";

const OptionSelect = () => import("@/components/Widgets/Select/Select2.0/OptionSelect");
import axios from "axios";
import { domain } from "@/globalVariables";

export default {
  name: "ModalSelect",
  mixins: [],
  props: {
    filterActive: {
      type: Boolean,
      default: true
    },
    value: {
      type: Array,
      default: () => {
        return [];
      }
    },
    placeholder: {
      type: String,
      default: ""
    },
    itemName: {
      type: String,
      default: ""
    },
    entity: {
      type: String,
      default: ""
    },
    actionEntity: {
      type: String,
      default: ""
    },
    multiple: {
      type: Boolean,
      default: ""
    },
    maxCount: {
      type: Number
    },
    createMode: {
      type: Boolean
    },
    hiddenItems: {
      type: Array
    },
    createEntity: {
      type: String
    },
    channelUsers: {
      type: Array
    },
    is_deleted: {
      type: Boolean
    },
    filterType: {
      type: Array
    },
    isAdminRegulation: {
      type: Boolean
    },
    isRegulation: {
      type: Boolean
    },
    ispGroup: {
      type: Boolean
    }
  },
  components: { OptionSelect },
  data() {
    return {
      options: [],
      loader: true,
      inputSearchValue: "",
      timerId: 0,
      hoverIndex: null,
      searchFindId: false,
      listenerEvent: null
    };
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.eventsOptions, false);
  },
  mounted() {
    this.$refs.inputModal.focus();
    window.addEventListener("keydown", this.eventsOptions, false);
    this.getEntityData();
  },
  methods: {
    eventsOptions(event) {
      if (event.key === "ArrowUp") {
        if (this.hoverIndex && this.hoverIndex > this.findUnChosenIndex()) {
          this.hoverIndex--;
        } else {
          this.findHoverIndex();
        }
      }
      if (event.key === "ArrowDown") {
        if (this.hoverIndex === this.options.length - 1) return false;
        if (this.hoverIndex !== null) {
          this.hoverIndex++;
        } else {
          this.findHoverIndex();
        }
      }
      if (event.key === "Enter") {
        if (this.hoverIndex !== null && this.options[this.hoverIndex]) {
          this.pushOption(this.options[this.hoverIndex]);
        }
      }
      // if (event.key === "Backspace") {
      //   this.value.splice(this.value.length - 1, 1);
      // }
      if (event.key === "Escape") {
        this.$root.$emit("hideSelectModal");
      }
    },
    getEntityData() {
      this.hoverIndex = null;
      httpClient({
        url: `/${this.entity}/${this.actionEntity}?_search={"${this.itemName}":"${this.inputSearchValue}"}`,
        method: "GET",
        params: {
          _count: 300,
          _with: this.ispGroup ? JSON.stringify(["member_users"]) : undefined,
          _order: this.entity === "Level" ? { order: "asc" } : undefined,
          channel_users: this.channelUsers
            ? JSON.stringify([{ "users.id": this.getUserId }])
            : undefined,
          type: this.filterType ? this.filterType : undefined,
          is_active:
            this.entity === "User"
              ? this.filterActive
                ? JSON.stringify([1])
                : JSON.stringify([0])
              : undefined,
          is_deleted:
            this.entity === "ISPGroup" && !this.is_deleted
              ? false
              : this.entity === "ISPGroup" && this.is_deleted
              ? true
              : undefined,
          is_archived: this.entity === "Department" ? false : undefined,
          users:
            this.isRegulation && !this.isAdminRegulation
              ? { "users.id": this.getUserId }
              : undefined
        }
      })
        .then((response) => {
          this.options = response.data.data.items.filter(
            (item) => item.email !== "smartfox@smartworld.team"
          );
          this.loader = false;
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    },
    createNewItem() {
      if (!this.createMode || !this.inputSearchValue) return false;
      httpClient({
        url: `/${this.entity}/${this.createEntity}`,
        method: "POST",
        data: [
          {
            name: this.inputSearchValue
          }
        ]
      })
        .then((response) => {
          this.pushOption(response.data.data);
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    },
    selectAllOption() {
      this.$emit("pushAllOption", this.options);
    },
    checkSearchResults() {
      clearTimeout(this.timerId);
      this.timerId = setTimeout(() => {
        this.getEntityData();
      }, 500);
    },
    pushOption(option) {
      this.$emit("pushOptionToValue", option);
      this.inputSearchValue = "";
      this.getEntityData();
    },
    deleteOption(option) {
      this.$emit("deleteOptionToValue", option);
    },
    findHoverIndex() {
      for (let i in this.$refs.modalBg.children) {
        let child = this.$refs.modalBg.children[i];
        if (child.dataset.index) {
          this.hoverIndex = parseInt(child.dataset.index);
          break;
        }
      }
    },
    findUnChosenIndex() {
      for (let i in this.$refs.modalBg.children) {
        let child = this.$refs.modalBg.children[i];
        if (child.dataset.index) {
          return parseInt(child.dataset.index);
        }
      }
    }
  },
  watch: {
    hoverIndex() {
      for (let i in this.$refs.modalBg.children) {
        let child = this.$refs.modalBg.children[i];
        if (this.hoverIndex === parseInt(child.dataset.index)) {
          this.$refs.modalBg.scrollTop = child.offsetTop - child.offsetHeight;
        }
      }
    }
  },
  validations: {},
  computed: {
    getUserId() {
      return this.$store.getters.getUserId;
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.modal-items {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 15;
  width: 100%;
  max-height: 200px;
  background: #fafbfc;
  border-radius: 0 0 24px 24px;
  overflow-y: auto;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.03);
  padding: 2px 12px;
  @media (max-width: $lg) {
    max-height: 100px;
  }

  input {
    height: 30px;
    background: #ffffff;
    color: $text-subdued;
    outline: none;
    flex-shrink: 0;
    padding: 6px;
    margin: 10px 6px;
    border-radius: 14px;
    border: 1px solid $border-disabled;
  }

  input::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $text-disabled;
  }

  .chosen-options-bg {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .chosen-options {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 13px;
      user-select: none;
      color: $text-default;
      margin: 0 6px 6px 4px;
      background: #c1c7d0;
      padding: 2px 4px;
      border-radius: 14px;
      max-width: 100%;

      div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .icon-cross {
        font-size: 10px;
        cursor: pointer;
        margin-left: 5px;
      }

      @media screen and (max-width: $xl) {
        height: auto;
      }
    }
  }

  .button-chose-all {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 6px;
    cursor: pointer;
    user-select: none;
    flex-shrink: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $text-default;
  }

  .button-chose-all:hover {
    color: $text-subdued;
  }

  .empty-bg {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 13px;
    color: $text-default;
  }

  .loader-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;

    .loader-content {
      display: inline-block;
      position: relative;
      width: 80px;
      height: 100%;
    }

    .loader-content div {
      position: absolute;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background: $action-primary;
      animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }

    .loader-content div:nth-child(1) {
      left: 8px;
      animation: loader-content1 0.6s infinite;
    }

    .loader-content div:nth-child(2) {
      left: 8px;
      animation: loader-content2 0.6s infinite;
    }

    .loader-content div:nth-child(3) {
      left: 32px;
      animation: loader-content2 0.6s infinite;
    }

    .loader-content div:nth-child(4) {
      left: 56px;
      animation: loader-content3 0.6s infinite;
    }

    @keyframes loader-content1 {
      0% {
        transform: scale(0);
      }
      100% {
        transform: scale(1);
      }
    }
    @keyframes loader-content3 {
      0% {
        transform: scale(1);
      }
      100% {
        transform: scale(0);
      }
    }
    @keyframes loader-content2 {
      0% {
        transform: translate(0, 0);
      }
      100% {
        transform: translate(24px, 0);
      }
    }
  }
}
</style>
